import React, { Component } from "react";

import Fade from "react-reveal/Fade";

import Data from "../json/landingPage.json";

export default class ServicesPageList extends Component {
  render() {
    const data = Data.service;
    return (
      <div className='bg-gray-50'>
        <div className='container  mx-auto pt-20 pb-28'>
          <div className='grid grid-rows-3 px-10 gap-8 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6 xl:gap-16'>
            {data.map((item, index) => (
              <Fade bottom delay={50}>
                <div>
                  <div className='w-full h-full bg-white  group rounded-2xl shadow-2xl border border-theme-gray transform transition duration-500 hover:scale-105'>
                    <img
                      src={item.imageUrl}
                      alt='Service'
                      className='w-1/2 h-3/2 rounded-t-2xl ml-auto mr-auto'
                    />
                    <h2 className='text-theme-blue text-center text-xl text-center py-7 rounded-b-2xl'>
                      {item.title}
                    </h2>
                    <p className='text-theme-blue text-center text-sl p-3 py-7 rounded-b-2xl'>
                      {item.tech}
                    </p>
                    <p className='text-theme-blue p-10 text-sl py-7 rounded-b-2xl '>
                      {item.description}
                    </p>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
