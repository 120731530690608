import React, { Component } from "react";

import Fade from "react-reveal/Fade";

import Header from "../parts/Header";

import Footer from "../parts/Footer";

export default class Privacypolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  // grid grid-rows-4 justify-center px-10 gap-4 sm:grid-cols-4 sm:grid-rows-1
  render() {
    return (
      <div>
        <Header {...this.props} />
        <div className='flex bg-gray-50 min-h-screen justify-center'>
          <div className='items-center text-theme-blue mx-16 my-16'>
            <h1 className=' text-4xl text-white text-center mb-2 bg-theme-blue'>
              Privacy Policy
            </h1>
            <p className='text-md mt-5 '>
              This privacy policy discloses the privacy practices of 1 Ten
              Solutions. 1 Ten Solutions wants to prove its commitment to your
              privacy, and we have agreed to disclose our information and
              privacy practices. Therefore, we take this opportunity to inform
              you of the following information: What personal data about you or
              a third party has been collected from you through the website. The
              organization that collects the information. How to use
              information. Who can share information with. What are your choices
              regarding the collection, use and dissemination of information?
              The type of security procedures implemented to prevent loss,
              misuse or alteration of information are under the control of 1 Ten
              Solutions. How do you correct any inaccuracies in the information.
              If you believe that the company does not comply with its published
              privacy policy, you should first contact 1 Ten Solutions by
              sending an email to contact@1tensolutons.com
            </p>

            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Information Collection and Use
            </h2>
            <p className='text-md mt-5 '>
              1 Ten Solutions is the sole owner of the information collected on
              this website. We will not sell, share or rent this information in
              any way other than those disclosed in this statement. We will
              collect user information in different places on our website.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Registration and Forms
            </h2>
            <p className='text-md mt-5 '>
              In order to use this website, users sometimes have to fill out an
              online form. During the form process, users must provide their
              contact information (such as name and email address). This
              information is used to contact the user and learn about the
              services on our website that he has expressed interest in. Users
              can choose to provide demographic information (such as income
              level and gender), but this is recommended so that we can provide
              a more personalized experience on our website.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Sessions
            </h2>
            <p className='text-md mt-5 '>
              A session is a data element stored on the server side, which
              contains information about the user. When you visit our website,
              the use of the session has nothing to do with personally
              identifiable information. Once the user closes the browser, the
              session ends. When a user logs in, a session will be established
              on our website, that is, the user does not have to log in with a
              password multiple times, thus saving time on our website. Even if
              the user is not logged in, he can still use our website. The only
              disadvantage is that users cannot access all functions. For
              example, users cannot see tattoo accounts or access certain
              services we provide. Sessions can also enable us to pursue and
              specifically address user interests in order to improve the
              experience on our website. Some of our business partners may use
              sessions on our website (for example, advertisers). However, we
              cannot access or control these sessions.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Log Files
            </h2>
            <p className='text-md mt-5 '>
              We use IP addresses to analyze trends, manage websites, track user
              movements, and collect comprehensive demographic information for
              aggregate use.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>Sharing</h2>
            <p className='text-md mt-5 '>
              We will proportion aggregated demographic facts with our
              companions and advertisers. This isn't always related to any
              private facts which could become aware of any man or woman person.
              We will use a credit score card processing employer to invoice
              customers for items and services. These organizations do now no
              longer retain, proportion, shop or use individually identifiable
              facts for any secondary purposes. We accomplice with some other
              birthday birthday celebration to offer unique services. When the
              person symptoms and symptoms up for those services, we can
              proportion names, or different touch facts this is essential for
              the 0.33 birthday birthday celebration to offer those services.
              These events aren't allowed to apply individually identifiable
              facts besides for the reason of offering those services.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>Links</h2>
            <p className='text-md mt-5 '>
              This website contains links to other websites. Please note that
              we, 1 Ten Solutions, are not responsible for the privacy practices
              of these other websites. We encourage our users to be careful when
              leaving our website and read the privacy statement of each website
              that collects personal data. This privacy policy only applies to
              information collected from this website.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Newsletter
            </h2>
            <p className='text-md mt-5 '>
              If a user wants to subscribe to our newsletter, we will ask for
              contact information, such as name and email address.
            </p>

            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Email To A Friend
            </h2>
            <p className='text-md mt-5 '>
              When users use our recommendation service to introduce our website
              to friends, we will ask them to provide their friends' names and
              email addresses. 1 Ten Solutions will automatically send a
              one-time e-mail to friends, inviting them to visit the website. 1
              Ten solutions only store this information to send this one-time
              email. The friend can contact 1 Ten Solutions to request that this
              information be deleted from their database.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Site and Service Updates
            </h2>
            <p className='text-md mt-5 '>
              We also send updates to user sites and service announcements.
              Members cannot unsubscribe from service announcements that contain
              important service information. We communicate with users via email
              or phone to provide requested services and clarify questions about
              their accounts.
            </p>

            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Correction/Updating Personal Information:
            </h2>
            <p className='text-md mt-5 '>
              If the user's personal data changes (such as your postal code) or
              the user no longer wants our services, we will try to correct,
              update or delete the personal data that the user provides to us.
              This can usually be done on the member information page or by
              sending the information to [at] contact@1tensolutions.com.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Notification of Changes
            </h2>
            <p className='text-md mt-5 '>
              If we decide to change our privacy policy, we will post these
              changes on our homepage so that our users always know what
              information we collect, how we use it, and how we may pass it on.
              If at any time we decide to use personal information in a way
              other than the one specified at the time of collection, we will
              notify the user via email. Users can choose whether we use their
              information in other ways. We use information in accordance with
              the privacy policy under which the information was collected.
            </p>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}
