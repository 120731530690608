/* eslint-disable react/jsx-filename-extension */

import React from "react";

import Fade from "react-reveal/Fade";

import Button from "elements/Button";

import BuildWebsite from "assets/images/Startup.png";
import { Typewriter } from "react-simple-typewriter";

export default function Hero() {
  return (
    <section className='hero'>
      <Fade bottom>
        <div className='w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8 text-justify'>
          <h1 className='text-5xl sm:text-6xl text-theme-blue font-bold leading-tight mb-5'>
            <Typewriter
              loop
              cursor
              cursorStyle='!'
              typeSpeed={60}
              deleteSpeed={40}
              delaySpeed={4000}
              words={["Design", "Develop", "Deploy"]}
            />
          </h1>

          <p className='font-light text-xl text-theme-blue text-left mt-10'>
            Welcome to 1 Ten Solutions! We are a premier software company that specializes in designing and developing cutting-edge websites, software, and mobile applications. 
          </p>
          <p className='font-light text-xl text-theme-blue text-left leading-relaxed mt-5 '>
            We take pride in delivering exceptional services that are tailored to meet our clients' unique needs.
          </p>

          <div>
            <Button
              href='/portfolio'
              type='link'
              className='flex w-44 h-18 items-center mt-10 lg:ml-0 pl-4 py-5 text-white text-lg bg-theme-blue border-2 rounded-lg shadow-2xl hover:bg-white hover:border-theme-blue hover:text-theme-blue transition duration-600'
            >
              Explore More.
              <svg
                className='ml-2 w-7 h-7 text-white animate-bounce-x hover:text-theme-blue'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M9 5l7 7-7 7'
                />
              </svg>
            </Button>
          </div>
        </div>
      </Fade>

      <Fade bottom>
        <div className='flex pt-5 w-full justify-center items-center ml-20order-first md:w-full lg:order-last lg:w-2/5'>
          <img className='' src={BuildWebsite} alt='Build Website' />
        </div>
      </Fade>
    </section>
  );
}
