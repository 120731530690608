/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";

import Header from "parts/Header";
import HeroAbout from "parts/AboutHero";
import Footer from "parts/Footer";


export default class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <HeroAbout {...this.props} />

        <Footer />
      </>
    );
  }
}
