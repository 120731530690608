/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

import Header from "../parts/Header";

import ServiceHero from "../parts/ServiceHero";

import Footer from "../parts/Footer";

import ServicesPageList from "parts/ServicesPageList";


export default class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header {...this.props} />
        <ServiceHero />
        <ServicesPageList />
        <Footer {...this.props} />
      </div>
    );
  }
}
