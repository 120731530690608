/* eslint-disable react/jsx-filename-extension */

import React from "react";

import Fade from "react-reveal/Fade";

import Solution from "../assets/images/solutions.jpg";

export default function SolutionsHero() {
  return (
    <section className='hero'>
      <Fade bottom>
        <div className='w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-32'>
          <h1 className='text-5xl sm:text-6xl text-theme-blue font-bold leading-tight mb-5'>
            Our Solutions
          </h1>

          <p className='font-light text-xl text-theme-blue leading-relaxed mb-16'>
            We craft intelligent solutions to solve everyday situations through
            agile and sedulous processes.
          </p>
        </div>
      </Fade>

      <Fade bottom>
        <div className='flex pt-5 w-full justify-center items-center ml-20order-first md:w-full lg:order-last lg:w-2/5'>
          <img className='' src={Solution} alt='Our Solutions' />
        </div>
      </Fade>
    </section>
  );
}
