/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

import About from 'assets/images/about.png';

export default function HeroTeam() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-16 text-justify tracking-tighter">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight mb-5">
            About Us
          </h1>
          <p className="font-light text-xl text-theme-blue leading-relaxed tracking-tighter">
            Welcome to 1 Ten Solutions! <br/><br/>
           Established in 2014, 1 Ten Solutions is a leading software company that excels in providing innovative and cutting-edge services in the areas of Website, Software, and Mobile Application Design and Development. 
          Our journey began with a vision to empower businesses and individuals with top-notch digital solutions to meet the demands of a rapidly evolving technological landscape.<br/><br/>
         What sets us apart is our relentless pursuit of excellence and a customer-centric approach. We believe that every project, big or small, deserves the utmost dedication and attention to detail. 
    With a team of highly skilled and passionate professionals, we strive to deliver solutions that not only meet but exceed our clients' expectations.<br/><br/>
    Our portfolio boasts several successful international projects, and we take immense pride in the impact we have made on various businesses across the globe. From small startups to large enterprises, 
    we have collaborated with clients from diverse industries, gaining valuable insights and experience that have sharpened our capabilities.<br/><br/>
       </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12 mt-20">
          <img src={About} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
