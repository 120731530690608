import React from "react";

import Fade from "react-reveal/Fade";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default function TechSlider({ data }) {
  return (
    <Fade bottom>
      <section className='container mx-auto'>
        <h1 className='text-5xl text-theme-blue text-center font-bold '>
          Technologies
        </h1>

        <p className='font-light text-lg text-theme-blue text-center mb-3 sm:mb-3 xl:mb-4'>
          Technologies we use
        </p>

        <Splide
          options={{
            type: "loop",
            autoplay: true,
            pagination: false,
            perPage: 3,
            focus: "center",
            height: "9rem",
            rewind: true,

            breakpoints: {
              640: {
                perPage: 2,
                gap: "1rem",
              },
              480: {
                perPage: 1,
                gap: "1rem",
              },
            },
          }}
        >
          {data.map(item => (
            <SplideSlide>
              <img
                src={item.imageUrl}
                alt={item.alt}
                className='h-full ml-auto mr-auto items-center '
              />
            </SplideSlide>
          ))}
        </Splide>
      </section>
    </Fade>
  );
}
