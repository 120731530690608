import React, { Component } from "react";

import Fade from "react-reveal/Fade";

import Data from "../json/landingPage.json";

import Header from "../parts/Header";

import SolutionsHero from "../parts/SolutionsHero";

import Footer from "../parts/Footer";

export default class Solutions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const data_mob = Data.solutions_mobile;
    const data_web = Data.solutions_web;
    return (
      <div>
        <Header {...this.props} />
        <SolutionsHero />
        <h1 className='text-5xl w-full text-theme-blue text-center font-bold'>
          Web Development
        </h1>
        <div className='p-3'>
          <p className='text-sl text-theme-blue text-center '>
            All you need to have is an idea or a plan or a design! We will take
            care of the rest and convert it into an app or a website <br /> that
            will exceed your requirements and expectations.
          </p>
        </div>

        <div className='bg-gray-50'>
          <div className='container  mx-auto pt-20 pb-28'>
            <div className='grid grid-rows-3 text-center px-10 gap-8 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6 xl:gap-16'>
              {data_web.map((item, index) => (
                <Fade bottom delay={50}>
                  <div>
                    <div className='w-full xl:h-full md:h-1/2 md:w-full bg-white ml-auto mr-auto group rounded-2xl shadow-2xl transform transition duration-500 hover:scale-105'>
                      <img
                        src={item.imageUrl}
                        alt='Service'
                        className='w-1/5 rounded-t-2xl ml-auto mr-auto pt-4 '
                      />
                      <h2 className='text-theme-blue  text-center text-xl py-7 rounded-b-2xl'>
                        {item.title}
                      </h2>

                      <p className='text-theme-blue p-10 text-sl  py-7 rounded-b-2xl '>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
        <h1 className='text-5xl text-theme-blue text-center font-bold'>
          Mobile App Development
        </h1>
        <div className='p-3'>
          <p className='text-sl text-theme-blue text-center '>
            All you need to have is an idea or a plan or a design! We will take
            care of the rest and convert it into an app or a website <br /> that
            will exceed your requirements and expectations.
          </p>
        </div>
        <div className='bg-gray-50'>
          <div className='container  mx-auto pt-20 pb-28'>
            <div className='grid grid-rows-3 px-10 gap-8 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6 xl:gap-16'>
              {data_mob.map((item, index) => (
                <Fade bottom delay={400 * index}>
                  <div>
                    <div className='w-full xl:h-full rounded-lg md:h-1/2 md:w-full bg-white ml-auto mr-auto group rounded-4xl shadow-2xl transform transition duration-500 hover:scale-105'>
                      <img
                        src={item.imageUrl}
                        alt={item.title}
                        className='w-1/5 rounded-t-2xl ml-auto mr-auto pt-4'
                      />
                      <h2 className='text-theme-blue  text-center text-xl py-7 rounded-b-2xl'>
                        {item.title}
                      </h2>

                      <p className='text-theme-blue p-10 text-sl  py-7 rounded-b-2xl text-center '>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}
