import React, { useState } from "react";

import Fade from "react-reveal/Fade";
import NotFound from "assets/images/NotFound.png";
import Button from "elements/Button";
import ModalImage from "react-modal-image";

export default function PortfolioDetail({ data }) {
  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen(prev => !prev);
  if (data === null) {
    return (
      <section className='container mx-auto'>
        <Fade bottom>
          <div className='flex flex-col w-full justify-center'>
            <div className='flex w-full justify-center'>
              <img
                src={NotFound}
                alt='Not Found'
                className='sm:w-3/4 xl:w-5/12 mt-5'
              />
            </div>
            <h1 className='text-theme-blue text-2xl text-center mt-5'>
              Project Not Found
            </h1>
            <div className='flex justify-center'>
              <Button
                href='/project'
                type='link'
                className='flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-600 hover:bg-gray-900'
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section className='container mx-auto'>
      <Fade bottom>
        <Button
          type='link'
          href='/portfolio'
          className='flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-theme-blue hover:underline'
        >
          <svg
            className='w-5 h-5 text-theme-blue mr-1'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M15 19l-7-7 7-7'
            />
          </svg>
          See All Projects
        </Button>
      </Fade>

      {data.map(item => (
        <div className='flex flex-col mt-8 justify-center'>
          <Fade bottom>
            <h1 className='text-5xl text-theme-blue text-center font-bold'>
              {item.title}
            </h1>

            <p className='font-light text-xl text-theme-blue text-center mb-10'>
              {item.type}
            </p>
          </Fade>

          <div className='flex justify-center xl:mb-6'>
            {/* <img
                src={`../${item.imageUrl}`}
                alt='Project'
                className='flex w-4/5 sm:w-4/6'
              />  */}
            <ModalImage
              small={`../${item.imageUrl1}`}
              large={`../${item.imageUrl2}`}
              alt='Portfolio'
              hideDownload='false'
              // imageBackgroundColor='black'
              style={{ overlay: { background: "black" } }}
            />
          </div>

          <Fade bottom delay={300 * 1}>
            <div className='flex flex-col mt-16 mb-12 mx-8 sm:mx-16 xl:mx-28'>
              <h1 className='text-3xl text-theme-blue font-bold mb-3'>
                Project Detail
              </h1>

              <p className='font-light text-lg text-theme-blue text-justify mb-2'>
                {item.details}</p>
                <p className='font-light text-lg text-theme-blue text-justify mb-2'>
                  Features of the website :
                </p>
                <p className='font-light text-lg text-theme-blue text-justify'>
                {item.features.map(features => (
                  <div className='flex flex-row ml-1'>{features}</div>
                ))}
              </p>
            </div>
          </Fade>
          <div className='flex flex-col mx-8 sm:mx-16 xl:mx-28'>
            <h1 className='text-3xl text-theme-blue font-bold mb-5'>
              Project Responsibilities
            </h1>

            <div className='flex flex-row ml-1'>
              {item.responsibility.map(responsibility => (
                <div className='mr-4 px-6 py-3 text-theme-blue border border-theme-blue rounded-full shadow-lg hover:bg-theme-blue hover:text-theme-white'>
                  {responsibility}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}
