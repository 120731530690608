/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Fade from "react-reveal/Fade";

import heroPortfolio from "assets/images/Portfolio.png";

export default function HeroPortfolio() {
  return (
    <section className='hero sm:items-center lg:items-start sm:flex-row'>
      <Fade bottom>
        <div className='w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20'>
          <h1 className='text-6xl text-theme-blue font-bold leading-tight mb-5'>
            Portfolio
          </h1>
          <p className='font-light text-xl text-theme-blue leading-relaxed'>
            We are here to help you <b>Design</b> eyecatching UI, <b>Develop</b>{" "}
            top notch application and <b>Deploy</b> your product to take over
            the market.
          </p>
        </div>
        <div className=' sm:w-1/2 sm:pr-12 '>
          <img src={heroPortfolio} width='90%' alt='Hero' />
        </div>
      </Fade>
    </section>
  );
}
