import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Transition } from "@headlessui/react";
import Button from "elements/Button";
import BrandIcon from "parts/BrandIcon";
import "../styles/Header.css";

export default function Header(props) {
  const { location } = props;
  const [isCollapse, setIsCollapse] = useState(false);
  const path = location.pathname;

  return (
    <header className='header bg-theme-blue '>
      <Fade>
        <div className='flex justify-between px-7 lg:px-0'>
          <BrandIcon />

          <h1 className='text-md w-full sm:text-2xl hidden lg:flex text-theme-white font-bold leading-tight mt-4 '>
            1 Ten Solutions
          </h1>

          <button
            className='block text-theme-white lg:hidden focus:outline-none'
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <svg
              className='w-8 h-8'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                className={`${isCollapse ? "hidden" : "block"}`}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16m-7 6h7'
              />
              <path
                className={`${!isCollapse ? "hidden" : "block"}`}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>

        <ul className='hidden  text-theme-white tracking-widest items-center lg:flex flex-row mb-4'>
          <li>
            <Button
              className={`${
                path === "/aboutus" ? "active-link" : ""
              } text-md px-2 no-underline hover:text-blue-400`}
              type='link'
              href='/about'
            >
              About Us
            </Button>
          </li>
          <li>
            <Button
              className={`${
                path === "/solutions" ? "active-link" : ""
              } text-md px-2 no-underline hover:text-blue-400`}
              type='link'
              href='/solutions'
            >
              Solutions
            </Button>
          </li>
          <li>
            <Button
              className={`${
                path === "/services" ? "active-link" : ""
              } text-md px-2 no-underline hover:text-blue-400`}
              type='link'
              href='/services'
            >
              Services
            </Button>
          </li>
          <li className='py-2 lg:py-0'>
            <Button
              className={`${
                path === "/portfolio" ? "active-link" : ""
              } text-md px-2 no-underline  text-theme-blue-500 hover:text-blue-400`}
              type='link'
              href='/portfolio'
            >
              Portfolio
            </Button>
          </li>

          <li>
            <Button
              className='text-md ml-3 mx-auto px-4 py-2  bg-theme-blue text-theme-white rounded-md border-2 border-theme-white  transition duration-600 header_btn-hover'
              type='link'
              href='/contact'
            >
              {" "}
              Start Project
            </Button>
          </li>
        </ul>
      </Fade>

      <Transition
        show={isCollapse}
        enter='transition-opacity duration-600'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-600'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Fade>
          <ul className='z-50 text-center flex flex-col text-theme-white tracking-widest my-4 absolute bg-theme-blue w-full border-b-2 border-gray-300 lg:hidden'>
            <li className='py-2 bg-theme-blue'>
              <Button
                className={`${
                  path === "/about" ? "active-link" : ""
                } text-md px-10 no-underline hover:text-blue-400`}
                type='link'
                href='/about'
              >
                About Us
              </Button>
            </li>
            <li className='py-2 bg-theme-blue'>
              <Button
                className={`${
                  path === "/solutions" ? "active-link" : ""
                } text-md px-10 no-underline hover:text-blue-400`}
                type='link'
                href='/solutions'
              >
                Solutions
              </Button>
            </li>
            <li className='py-2 bg-theme-blue'>
              <Button
                className={`${
                  path === "/services" ? "active-link" : ""
                } text-md px-10 no-underline hover:text-blue-400`}
                type='link'
                href='/services'
              >
                Services
              </Button>
            </li>
            <li className='py-2 bg-theme-blue'>
              <Button
                className={`${
                  path === "/portfolio" ? "active-link" : ""
                } text-md px-10 no-underline hover:text-blue-400`}
                type='link'
                href='/portfolio'
              >
                Portfolio
              </Button>
            </li>
            <li className='mx-auto my-9 bg-theme-blue'>
              <Button
                className='text-md mx-auto px-2 py-2 bg-theme-blue text-white rounded-xl border-2 border-theme-white hover:bg-dark-theme-blue border-blue-800 transition duration-600'
                type='link'
                href='/contact'
              >
                Start Project
              </Button>
            </li>
          </ul>
        </Fade>
      </Transition>
    </header>
  );
}
