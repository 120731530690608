import React, { Component } from "react";

import Header from "../parts/Header";

import Footer from "../parts/Footer";

export default class Disclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header {...this.props} />
        <div className='flex bg-gray-50 min-h-screen justify-center '>
          <div className='items-center text-theme-blue mx-16 my-16'>
            <h1 className=' text-4xl text-white text-center mb-2 bg-theme-blue'>
              Disclaimer
            </h1>
            <p className='text-md mt-5 '>
              To the fullest extent permissible by law, we disclaim any
              representations, warranties, and conditions relating to our
              website and its use (including, without limitation, any implied
              warranties of satisfactory quality, fitness for purpose, and/or
              the use of reasonable care and skill).
            </p>
            <ul className='list-disc ml-10'>
              <li>
                Restrict or exclude our or your liability for negligence-related
                death or bodily harm;
              </li>
              <li>
                Limit or exclude our or your liability for deception or fraud;
              </li>
              <li>
                Restrict any of our or your liabilities in any way that isn't
                legal; or{" "}
              </li>
              <li>
                Exclude any of our or your liabilities that are not permitted by
                applicable law to be excluded.{" "}
              </li>{" "}
            </ul>
            <p>
              The limitations and exclusions of liability set forth in this
              Section and elsewhere in this disclaimer:
              <br /> (a) are subject to the preceding paragraph; <br />
              (b) apply to all liabilities arising under the disclaimer or in
              relation to the disclaimer's subject matter, including liabilities
              arising in contract, tort (including negligence), and for breach
              of statutory duty.To the extent that the website allows it,
            </p>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}
