/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import LandingPage from "pages/LandingPage";
import PortfolioPage from "pages/PortfolioPage";
import Services from "pages/Services";
import ProjectDetailPage from "pages/ProjectDetailPage";
import AboutUs from "pages/AboutUs";
import Disclaimer from "pages/Disclaimer";
import TnC from "pages/T&C";
import PP from "pages/PP";
import StartProjectPage from "pages/StartProjectPage";
import NotFoundPage from "pages/NotFoundPage";
import "assets/css/styles.css";
import Solutions from "pages/Solutions";

export default class App extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/portfolio' component={PortfolioPage} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/solutions' component={Solutions} />
        <Route exact path='/project/:id' component={ProjectDetailPage} />
        <Route exact path='/about' component={AboutUs} />
        <Route exact path='/disclaimer' component={Disclaimer} />
        <Route exact path='/TnC' component={TnC} />
        <Route exact path='/privacypolicy' component={PP} />
        <Route exact path='/contact' component={StartProjectPage} />
        <Route path='' component={NotFoundPage} />
      </Switch>
    );
  }
}
