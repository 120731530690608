/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Fade from "react-reveal/Fade";

import Button from "elements/Button";

export default function Discuss() {
  return (
    <Fade bottom>
      <section className='flex container mx-auto justify-center'>
        <div className='flex flex-col w-10/12 sm:w-11/12 xl:w-10/12 rounded-2xl bg-theme-blue text-gray-100 py-4 sm:px-12 sm:py-10 xl:px-16 shadow-2xl discuss'>
          <div className='flex flex-col sm:flex-row mb--2 sm:mb-9 p-5 sm:p-0 items-center'>
            <h1 className='w-full text-3xl sm:text-6xl leading-tight font-semibold text-center'>
              Have something in your mind you think is worth it?
            </h1>
          </div>
          <p className='font-light p-5 sm:p-0 text-lg text-center sm:text-xl lg:text-2xl xl:text-xl'>
            Don't hesitate and discuss it with us!
          </p>
            <Button
                href='/contact'
                type='link'
                className=' flex bg-theme-white text-theme-blue mt-2 sm:mt-10 mx-auto items-center text-sm lg:text-lg xl:text-sm tracking-wider items-center justify-center lg:w-26 lg:h-12 xl:w-44 h-12 p-5 border-2 shadow-xl rounded-2xl transform transition duration-700 hover:scale-105 hover:border-theme-white hover:bg-opacity-0 hover:text-theme-white'
              >
                <span className=' text-align-center'>Lets Discuss!</span>
              </Button>
        </div>
      </section>
    </Fade>
  );
}
