import React from "react";
import Recaptcha from "react-google-recaptcha";
import Fade from "react-reveal/Fade";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "assets/images/contact.png";
import Form from "elements/Form";
import Button from "elements/Button";
import "../styles/reCap.css";

export default function DiscussForm(props) {
  const { data, resetForm } = props;
  let isVerified = false;

  function onChange(value) {
    isVerified = true;
    console.log("Captcha value:", value);
    console.log("isVerified:", isVerified);
  }

  const submitEmail = () => {
    const { name, email, message } = data;

    const templateParams = {
      from_name: `${name} ( ${email} )`,
      to_name: "1 Ten Solutions",
      message: message,
    };

    if (name !== "" && email !== "" && message !== "" && isVerified == true) {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_KEY,
          templateParams,
          process.env.REACT_APP_USER_ID
        )
        .then(
          () => {
            toast.success("Success! we'll get back to you soon. Thank you!");
            isVerified = false;
            resetForm();
          },
          error => {
            toast.error(error);
          }
        );
    } else {
      toast.error("Please fill out the blank form.");
    }
  };

  return (
    <section className='flex flex-col container  min-h-screen mx-auto items-center mt-40 '>
      <Fade bottom>
        <div className='hero'>
          <Fade bottom>
            <div className='flex pt-5 mx-auto w-full justify-center items-center ml-20order-first md:w-full lg:order-last lg:w-2/5'>
              <img
                className='w-auto mx-auto'
                src={Contact}
                alt='Our Solution'
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className='w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-0 '>
              <div className='flex flex-col sm:flex-row justify-center '>
                <Form
                  id='name'
                  name='name'
                  type='text'
                  value={data.name}
                  placeholder='Your name'
                  className='rounded-xl '
                  onChange={props.onChange}
                />
              </div>

              <div className='flex flex-col sm:flex-row justify-center  '>
                <Form
                  id='email'
                  name='email'
                  type='email'
                  value={data.email}
                  placeholder='Your email address'
                  className='rounded-xl'
                  onChange={props.onChange}
                />
              </div>

              <div className='flex flex-col items-center sm:flex-row mx-auto justify-center'>
                <Form
                  id='message'
                  name='message'
                  type='textarea'
                  value={data.message}
                  placeholder='Explain about your project idea'
                  className='rounded-xl w-full md:w-full h-full'
                  onChange={props.onChange}
                />
              </div>

              <div className='flex flex-col mx-auto items-center g-recaptcha .g-recap justify-center'>
                <Recaptcha
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={onChange}
                  data-size={"compact"}
                />
              </div>

              <Button
                className='flex flex-col justify-center text-xl mx-auto px-12 py-3 mt-5 items-center bg-theme-blue text-white rounded-full border-2 border-theme-blue hover:bg-theme-white hover:text-theme-blue border-blue-800 transition duration-600 focus:outline-none'
                type='button'
                onClick={submitEmail}
              >
                Submit
              </Button>
            </div>
          </Fade>
        </div>
      </Fade>

      <ToastContainer />
    </section>
  );
}
