/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Button from "elements/Button";
import Logo from "assets/images/logo.png";
export default function BrandIcon() {
  return (
    <Button className='' type='link' href='/'>
      <img className='w-52 ml-8 sm:ml-2' src={Logo} alt={"Logo"}></img>
    </Button>
  );
}
