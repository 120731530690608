import React, { Component } from "react";

import Header from "../parts/Header";

import Footer from "../parts/Footer";

export default class TermsConditions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header {...this.props} />
        <div className='flex bg-gray-50 min-h-screen justify-center '>
          <div className='items-center text-theme-blue mx-16 my-16'>
            <h1 className=' text-4xl text-white text-center mb-2 bg-theme-blue'>
              Terms and Conditions
            </h1>
            <p className='text-md mt-5 '>
              Welcome to 1 Ten Solutions These terms and conditions describe the
              rules and regulations for using the 1 Ten Solutions website. The
              following terms apply to these terms and conditions, privacy
              policies and disclaimers, and any or all agreements customer, you
              and your refer to you, visit this website and accept the company's
              terms and conditions. Company, ourself, us, our and us refer to
              our company. One party, both parties or we refer to both the
              customer and ourselves, as well as the customer or ourselves. All
              conditions are related to the provision, acceptance and
              consideration of payment. These conditions are the most
              appropriate way for the customer to perform our assistance
              process, whether it is through a fixed-term formal meeting or to
              satisfy the customer in the provision of company-specified
              servicesproducts Requirements, and applicable United States law.
              The use of the above terms or other words in the singular, plural,
              uppercase, andor hisher form is considered interchangeable and
              therefore refers to the same thing.
            </p>

            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Cookies{" "}
            </h2>
            <p className='text-md mt-5 '>
              We use cookies. By using the 1 Ten Solutions website, you agree to
              the use of cookies in accordance with the 1 Ten Solutions Privacy
              Policy. Most modern interactive websites use cookies to enable us
              to retrieve user details for each visit. Cookies are used in
              certain areas of our website to enable the functionality of that
              area and provide user-friendliness to visitors. Some of our
              affiliatesadvertising partners may also use cookies.{" "}
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Licensing{" "}
            </h2>
            <p className='text-md mt-5 '>
              Unless otherwise specified, the 1 Ten Solutions andor its
              licensors own the intellectual property rights of all materials on
              the 1 Ten Solution. All intellectual property rights are reserved.
              You can view andor print pages from https://www.1tensolutions.com
              for your personal use, subject to the restrictions set out in
              these terms and conditions. You may not
              <ul className='list-disc ml-10'>
                <li>Republish material from https://www.1tensolutions.com </li>
                <li>
                  Sell, rent or sub-license material from
                  https://www.1tensolutions.com{" "}
                </li>{" "}
                <li>
                  Reproduce, duplicate or copy material from
                  https://www.1tensolutions.com
                </li>
              </ul>
              Redistribute content from 1 Ten Solutions(unless content is
              specifically made for redistribution).
              <h2 className=' text-2xl underline mt-5 text-left mb-2'>
                Hyperlinks to our content
              </h2>
              <p>
                {" "}
                Without prior written permission, the following organizations
                can link to our website:
                <ul className='list-disc ml-10'>
                  <li>Government agencies</li> <li>Search engines </li>
                  <li>News organizations</li>
                  <li>
                    Online catalog distributors, if they include us in the
                    catalog, you can Link to our website like hyperlinks to
                    other listed companies' websites
                  </li>
                  <li>
                    System-wide recognized companies, except for advertisements
                    for non-profit organizations, charity shopping centers and
                    charitable organizations. These organizations are not
                    allowed to have hyperlinks to our website.
                  </li>
                </ul>
                If you belong to the organization listed in paragraph 2 above
                and are interested in linking to our website, you must notify us
                by sending an email to support@1tensolutions.com. Please include
                your name, company name, contact information (such as phone
                number andor email address) and the URL of your website, a list
                of all the URLs you want to link to our website, and the page on
                our page you want to link to URL. Wait 23 weeks for a response.
                approved organizations can refer to our website as follows :
                <br />
                By use of our company name; or use the uniform resource locator
                (URL) linked to it; or by using any other description of our
                website or materials linked to, in the linking party’s website
                The content above makes sense in the context and format. Without
                a trademark license agreement, the 1 Ten Solutions logo or other
                graphics may not be used for linking.
              </p>
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>Iframes</h2>
            <p className='text-md mt-5 '>
              Without prior permission and express written permission, you may
              not create a frame around our website or use any other technology
              to change the visual appearance or appearance of our website in
              any way.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Reservation of Rights
            </h2>
            <p className='text-md mt-5 '>
              We reserve the right to ask you to delete all links or specific
              links pointing to our website at any time in our sole discretion.
              You agree to delete all links to our website immediately after
              receiving such a request. We also reserve the right to change
              these terms and conditions and their linked guidelines at any
              time. By continuing to link to our website, you agree to be bound
              by these link conditions and abide by them.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Removal of links from our website
            </h2>
            <p className='text-md mt-5 '>
              If for any reason you find a link on our website or any linked
              website objectionable, you can contact us about it. We will
              consider the request to remove the link, but we are not obligated
              to do so or to respond directly to you. Although we make every
              effort to ensure that the information on this website is correct,
              we are not responsible for its completeness or accuracy; we also
              do not undertake to ensure that the website remains available or
              the materials on the website are kept up-to-date.
            </p>
            <h2 className=' text-2xl underline mt-5 text-left mb-2'>
              Content Responsibility
            </h2>
            <p className='text-md mt-5 '>
              We are not responsible for any content on your website. You agree
              to compensate and protect us from all claims caused by or on your
              website. Any link does not lead to any page of your website or any
              context that contains content or materials that may be construed
              as defamatory, obscene or criminal, or infringes, otherwise
              infringes or advocates any infringement or other infringement of
              the rights of third parties Content or materials.
            </p>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}
